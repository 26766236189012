import { Button, buttonClasses } from "@material-ui/core";
import { styled } from "@material-ui/styles";










export default function ContinueButton(props) {

  const StyledButton = styled(Button)((a) => ({
    [`&.${buttonClasses.root}`]: {
      backgroundColor: "#2bbdff",
      color: 'white',
      fontSize: "calc(1% + 1.4rem)",
      whiteSpace: 'nowrap',
      fontWeight: 700,
      padding: "0 4rem",
      width: "100%",
      borderRadius: "4.4rem",
      height: "3rem",
      maxWidth: 600,
      border: 'solid #2bbdff',
      marginBottom: 12,
      marginTop: 12,
      "&:hover": {
        color: '#2bbdff',
        border: 'solid #2bbdff'
      }
    },
  }))




  return (
    <center>
      <StyledButton  {...props}>{props.title || "Continue"}</StyledButton>
    </center>
  )
}