import { useRoutes } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import CreateRoutes from './routes';
import { LoadingBackDrop } from './components/general';








export default function App() {
  const { isReady, isSignedIn, userInfo } = useAuth();
  const routing = useRoutes(CreateRoutes({ ...userInfo, isSignedIn }));



  // FIREBASE TAKES A BIT TO INITIALIZE
  return isReady ? routing : <LoadingBackDrop open={true} />
}




