import { Avatar, Card, CardContent, Dialog, DialogContent, Grid, Slide, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { forwardRef, useState } from 'react';
import * as Icons from 'react-icons/all';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router';






export default function PromoCode({ userInfo }) {
  const [open, setOpen] = useState(false);
  const code = userInfo.conversion_triggers?.[0]?.trigger;
  const url = "https://packleashes.com/discount/" + code + "?redirect=/";
  const nav = useNavigate();



  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3} wrap='nowrap' justifyContent='space-between' >
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6" >
                YOUR FRIENDS AND FAMILY CODE
              </Typography>
              <Typography color="textPrimary" variant={code ? "h3" : 'body'} >
                {code ?? "Create Your Unique Code"}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar sx={{ backgroundColor: code ? green[600] : red[400], height: 56, width: 56 }} onClick={() => code ? setOpen(true) : nav('/dashboard/account')} >
                {code ? <Icons.IoQrCode /> : <Icons.FiAlertCircle />}
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>


      <Dialog open={!!open} onClose={() => setOpen(false)} TransitionComponent={Transition} keepMounted>
        <DialogContent>
          <QRCode value={url} onClick={() => window.open(url, "_blank")} />
        </DialogContent>
      </Dialog>
    </>
  )
}



const Transition = forwardRef(function T(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
});