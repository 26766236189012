import { Grid, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Button, Avatar, colors, IconButton, Badge, Card, Typography, ListItem, List, Slide } from "@material-ui/core";
import { Add, Cake, Pets, Star, Edit, Delete } from "@material-ui/icons";
import React, { forwardRef, useState } from "react";
import breeds from "../breeds";
import avatars from "../avatars";
import { LoadingBackDrop, TextField, ContinueButton, ErrorPopUp } from "../components/general";





export default function Step2View({ userInfo, updateUser }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pets, setPets] = useState(userInfo.pets || []);
  const [pet, setPet] = useState(0);
  const [avatarOpen, setAvatarOpen] = useState(false);





  const handleChange = (key) => (value) => {
    setPets(pets => {
      console.log(pet)
      pet[key] = value;
      return [...pets]
    })
  }



  const addNewModel = () => {
    const newModel = { ...stockPet }
    setPets(pets => [...pets, newModel]);
    setPet(newModel);
  }



  const removeModel = (pet) => {
    setPets(pets => pets.filter(p => p !== pet));
    setPet();
  }



  const handleSubmit = () => {
    setLoading(true);

    updateUser({ pets, step: 2 }).catch(e => {
      setError(e.message || e.code);
    }).finally(() => setLoading(false));
  }


  return (
    <Grid container direction='column' sx={styles.root} >

      <Grid item xs sx={{ width: '100%' }}>

        <List>
          {pets.map((p, i) => (
            <ListItem key={i} button onClick={() => setPet(pets[i])}>
              <Card sx={{ width: '100%', p: 2 }}>
                <IconButton onClick={(e) => { e.stopPropagation(); removeModel(p) }} sx={{ position: 'absolute', top: 0, right: 10 }}>
                  <Delete />
                </IconButton>
                <Grid container spacing={2} alignItems='center' direction='column' >
                  <Grid item>
                    <Avatar alt={p.name} src={(avatars.find(a => a.id === p.avatarId) || avatars[0]).url} style={styles.avatar()} />
                  </Grid>
                  <Grid item>
                    <Typography >{p.name}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </ListItem>
          ))}

          <ListItem button onClick={addNewModel} >
            <Card sx={{ width: '100%', p: 2 }}>
              <Grid container spacing={2} alignItems='center' direction='column' >
                <Grid item>
                  <Avatar>
                    <Add />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography >Add Model</Typography>
                </Grid>
              </Grid>
            </Card>
          </ListItem>
        </List>

      </Grid>

      {/* CONTINUE BUTTON */}
      <Grid item sx={{ width: '100%' }}>
        <ContinueButton disabled={!pets.length} onClick={handleSubmit} id="btn" />
      </Grid>




      {/* EDIT MODEL POPUP */}
      <ModelPopUp {...{ pet, setPet, setAvatarOpen, handleChange }} />





      {/* AVATAR POPUP */}
      <Dialog open={avatarOpen} sx={{ zIndex: 9999 }} onClose={() => setAvatarOpen(false)} >
        <DialogTitle>Choose your model's avatar</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }}>
            {avatars.map((a, i) => (
              <Grid key={i} item xs={4} style={{ padding: 0 }}>
                <center>
                  <IconButton onClick={() => handleChange('avatarId')(a.id) || setAvatarOpen(false)}>
                    <Avatar src={a.url} sx={styles.avatar()} />
                  </IconButton>
                </center>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAvatarOpen(false)} >close</Button>
        </DialogActions>
      </Dialog >




      <LoadingBackDrop open={loading} />
      <ErrorPopUp open={!!error} onClose={() => setError(false)} content={error} />
    </Grid>
  )
}



const styles = {
  root: {
    height: '100%',
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  avatar: (selected) => ({
    width: 80,
    height: 80,
    backgroundColor: selected ? colors.blue[400] : colors.blue[200]
  }),
  addAvatar: {
    width: 40,
    height: 40,
    backgroundColor: colors.blue[200],
    margin: 3,
  },
  avatarContainer: {
    overflowX: 'auto',
    margin: 0,
    width: '100%',
    px: 1,
    //minHeight: 160,
  },
}



const stockPet = {
  name: '',
  bday: '',
  breed: '',
  avatarId: 0,
}


const AvatarContainer = ({ pet, setAvatarOpen }) => (
  <Grid container item wrap="nowrap" justifyContent='center' alignItems='center' sx={styles.avatarContainer} >
    <Grid item>
      <IconButton onClick={() => setAvatarOpen(true)} >
        <Badge badgeContent={<Edit scale={2} style={{ backgroundColor: 'antiquewhite', opacity: 0.8, padding: 3, borderRadius: '50%' }} />} overlap='circular'>
          <Avatar alt={pet.name} src={(avatars.find(a => a.id === pet.avatarId) || avatars[0]).url} style={styles.avatar()} />
        </Badge>
      </IconButton>
    </Grid>
  </Grid>
)




const ModelPopUp = ({ pet, setPet, setAvatarOpen, handleChange }) => {



  return (
    <Dialog open={!!pet} fullScreen TransitionComponent={Transition} onClose={() => setPet()}>
      {pet && (
        <DialogContent>
          <Grid container direction="column" alignItems='center' sx={{ width: `100%`, height: '100%' }}>

            <Grid item style={{ width: '100%' }}>
              <AvatarContainer {...{ pet, setAvatarOpen }} />
            </Grid>

            <Grid container item direction="column" spacing={3} xs sx={{ width: '100%', overflowY: 'auto' }} wrap='nowrap' >
              <Grid item  >
                <TextField placeholder='Pet Name*' value={pet?.name || ""} onTextChange={handleChange('name')} startAdornment={<Star />} />
              </Grid>
              <Grid item>
                <TextField type='date' pattern="\d{2}-\d{2}-\d{4}" placeholder="Bday or adoption day" label='Gotcha Day' value={pet?.bday} onTextChange={handleChange('bday')} shrink startAdornment={<Cake />} />
              </Grid>
              <Grid item>
                <Autocomplete autoSelect autoComplete defaultValue={pet?.breed || ""} freeSolo options={pet?.breed?.length ? breeds : []} onSelect={({ target }) => handleChange('breed')(target.value)} renderInput={(params) => (
                  <TextField  {...params} placeholder='Breed' startAdornment={<Pets />} />
                )} />
              </Grid>
            </Grid>

            <Grid item sx={{ width: '100%' }}>
              <ContinueButton onClick={() => setPet()} id="btn" title="Done" />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>

      </DialogActions>

    </Dialog>
  )
}



const Transition = forwardRef(function (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})