import { Grid, Container, Box, Alert } from '@material-ui/core';
import PromoCode from '../components/dashboard/PromoCode';
import CurrentPayout from '../components/dashboard/CurrentPayout';
//import Tier from '../components/dashboard/Tier';
import { useEffect, useState } from 'react';
import * as Icons from 'react-icons/all';
import { useNavigate } from 'react-router';
import { useAuth } from '../AuthProvider';
//import Chart from './Chart';
//import Orders from './Orders';




export default function DashboardView() {
  const [warnings, setWarnings] = useState([]);
  const nav = useNavigate();
  const { userInfo } = useAuth();

  useEffect(() => {
    const w = [];
    if (!userInfo.paypal_email) w.push({ m: "We're missing your payout info!", l: "/dashboard/account" });
    if (!userInfo.conversion_triggers?.[0]) w.push({ m: "Set your exclusive Friends and Family code!", l: "/dashboard/account" });
    if (!userInfo.photoCount) w.push({ m: "Upload your model photos so get featured on our IG!", l: "/dashboard/photo-submission" });
    setWarnings(w);
  }, [userInfo])


  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 1 }}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>

          {warnings[0] && (
            <Grid item xs={12}>
              <Alert sx={styles.alert} severity="warning" variant="filled" action={<Icons.FaExternalLinkAlt />} onClick={() => nav(warnings[0]?.l)} >
                {warnings[0]?.m}
              </Alert>
            </Grid>
          )}

          {/*<Grid item xs={12} md={4} lg={6}>
            <Tier />
          </Grid>*/}


          <Grid item xs={12} md={4} lg={6}>
            <PromoCode {...{ userInfo }} />
          </Grid>



          <Grid item xs={12} md={4} lg={6}>
            <CurrentPayout {...{ userInfo }} />
          </Grid>



        </Grid>

      </Container >
    </Box>
  )
}


const styles = {
  alert: {
    cursor: 'pointer',
  }
}



