import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../AuthProvider"






export default function LogoutView() {
  const { signOut } = useAuth();
  const nav = useNavigate()

  useEffect(() => {
    signOut().then(() => nav('/login'));
  }, [signOut, nav])


  return null
}