import React, { useState } from 'react';
import { Avatar, Button, colors, Grid, Box, Container, Typography } from '@material-ui/core';
import { LoadingBackDrop, TextField } from '../components/general';
import { LockOutlined } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import firebase from 'firebase';




const styles = {
	paper: {
		marginTop: 8 * 4,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: 'Poppins'
	},
	avatar: {
		margin: 4,
		backgroundColor: colors.blue[400],
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		mt: 4,

	},
	submit: {
		margin: "3px 0 2px",
		fontFamily: 'Poppins',
		backgroundColor: colors.blue[400]
	},
};



export default function ForgotView() {
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isSent, setSent] = useState(false);



	const handleSubmit = (e) => {
		e.preventDefault()
		setLoading(true)
		firebase.auth().sendPasswordResetEmail(email, { url: 'http://localhost:3000/login', }).then(() => setSent(true)).catch(setError).finally(setLoading)
	}



	return (
		<Container component="main" maxWidth="xs">
			<div style={styles.paper}>
				<Grid item>
					<img src="https://cdn.shopify.com/s/files/1/1649/7215/files/PACK_LOGO_FINAL_JUSTTYPE_TM_3fe8f11d-7e48-405b-a6fe-1829b34e0b2c.png?v=1613784681" alt="logo" style={{ height: "4.4rem", paddingTop: 15, paddingBottom: 15 }} />
				</Grid>
				<Avatar style={styles.avatar}>
					<LockOutlined />
				</Avatar>
				<Typography component="h1" variant="h5" fontFamily='Poppins'>
					Password Reset
        </Typography>
				<Typography color='error' fontFamily='Poppins'>{error || " "}</Typography>
				<form style={styles.form} noValidate onSubmit={handleSubmit} >
					<TextField margin="normal" align='left' required id="email" label="Email Address" name="email" autoComplete="email" autoFocus value={email} onTextChange={setEmail} error={!!error} disabled={loading} />

					<Button type="submit"  disabled={isSent || loading} fullWidth variant="contained" color='primary' style={styles.submit} disabled={!email || error || loading}>
						{isSent ? "reset link sent!" : "Send reset link"}
          </Button>
				</form>
			</div>


			<LoadingBackDrop open={loading} />
		</Container>
	);
}