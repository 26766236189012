import { Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ContinueButton, ErrorPopUp, TextField, LoadingBackDrop } from "../components/general";
import { getConversions, setPassword } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../AuthProvider";



export default function PayoutView() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [conversions, setConversions] = useState([]);
  const { userInfo } = useAuth();

  const from = new Date();
  from.setMonth(from.getMonth() - 1)
  from.setHours(0, 0, 0, 0);
  from.setDate(1);
  const to = new Date(from);
  to.setMonth(to.getMonth() + 1);
  to.setDate(0);

  const [range,] = useState({ from, to })



  useEffect(() => {
    getConversions({ affiliate_id: userInfo.refersion_id, from: range.from.valueOf() / 1000, to: range.to.valueOf() / 1000 }).then(({ data }) => {
      setConversions(data);
      console.log(conversions)
    }).catch(console.error).finally(setLoading);
  }, [userInfo.refersion_id, range])


  return (
    <Grid container direction='column' alignItems='center' wrap="nowrap" sx={styles.root} >
      <List sx={{ width: '100%' }}>
        {conversions.map(c => (
          <ListItem key={c.id} divider>
            <ListItemText primary={(new Date(c.created * 1000)).toLocaleDateString()} />
            <ListItemSecondaryAction>
              ${c.commission_total}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <LoadingBackDrop open={!!loading} />
      <ErrorPopUp open={!!error} onClose={setError} content={error} />
    </Grid >
  )
}



const styles = {
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    overflowY: 'auto'
  },
  headerContainer: {
    mt: 2
  },
  header: {
    color: '#333',
    fontSize: '2.4rem',
    fontWeight: 800,
  },
  content: {
    width: '100%'
  },
}