import { Button, Container, Dialog, DialogActions, DialogContent, Grid, LinearProgress } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../AuthProvider";
import { LoadingBackDrop, TextField } from "../components/general";
import { getSubmissions, uploadImages } from "../firebase";
import { useNavigate } from 'react-router-dom';








export default function PhotoSubmissionView() {
  const [loading, setLoading] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [preview, setPreview] = useState();
  const [needHandleOpen, setNeedHandleOpen] = useState(false);
  const [handle, setHandle] = useState("");
  const fileInput = useRef();
  const { userInfo, updateUser } = useAuth();
  const nav = useNavigate();



  useEffect(() => {
    if (userInfo.handle) {
      getSubmissions(userInfo.id).then(setSubmissions).finally(setLoading);
    } else {
      setLoading(false)
      setNeedHandleOpen(true)
    }
  }, [userInfo.handle, userInfo.id])


  const updateHandle = () => {
    updateUser({ handle }).then(() => {
      setNeedHandleOpen(false);
    }).finally(setLoading);
  }


  const handleSelection = ({ target }) => {
    const files = [...target.files].map(f => ({ file: f }));
    fileInput.current.value = "";
    const uploads = [];

    for (var f of files) {
      const img = new Image();
      img.src = URL.createObjectURL(f.file);
      f.resolution = `${img.naturalWidth}x${img.naturalHeight}`
      f.url = img.src;
      uploads.push(f);
    }

    setSubmissions(s => [...s, ...uploads]);
    uploadImages(userInfo, files).then(a => console.log(a)).finally(() => setSubmissions(s => s.map(ss => ({ ...ss, file: void 0 }))));

  }




  return (
    <Container maxWidth="lg" sx={{ py: 2, }}>
      <Grid container direction='column' spacing={2}>
        <Grid container item justify="space-between">
          <Grid item />
          <Grid item>
            <Button disabled={false} onClick={() => fileInput.current.click()} variant='outlined' >Upload</Button>
          </Grid>
        </Grid>
        <Grid container item spacing={1} style={{ overflowY: 'auto' }}>
          {submissions.map((p, i) => (
            <Grid item xs={4} lg={3} key={i} onClick={() => setPreview(p.url)}>
              <div style={styles.imageContainer}>
                <img src={p.url} alt="1" style={styles.image} />
                {p.file && (
                  <div style={styles.imageOverlay}>
                    <LinearProgress style={{ flex: 1 }} />
                  </div>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <input type='file' accept="image/*" multiple style={{ display: 'none' }} onChange={handleSelection} ref={fileInput} />


      {/* IMAGE PREVIEW */}
      <Dialog open={!!preview} onClose={() => setPreview()} fullWidth>
        <img src={preview} style={{ width: '100%' }} alt='preview' />
      </Dialog>

      {/* NEED HANDLE DIALOG */}
      <Dialog open={needHandleOpen} fullWidth>
        <DialogContent>
          <center>We want to tag you in your photos!</center>
          <br />
          <TextField value={handle} onTextChange={setHandle} placeholder="Instagram handle" />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-evenly">
            <Grid item><Button variant='outlined' onClick={() => nav('/dashboard')}>Go back</Button></Grid>
            <Grid item><Button variant='outlined' disabled={!handle} onClick={updateHandle}>Update</Button></Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <LoadingBackDrop open={!!loading} />
    </Container>
  )
}





const styles = {
  imageContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '100%',
  },
  image: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)",
    cursor: 'pointer',
  },
  imageOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black', opacity: 0.5
  },
}