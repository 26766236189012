import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { changeAffiliateCode, editAffiliate } from "../firebase";
import { useAuth } from "../AuthProvider";
import { ContinueButton, LoadingBackDrop, TextField } from "../components/general";








export default function AccountView() {
  const [loading, setLoading] = useState(false);
  const [mutated, setMutated] = useState(false);
  const { userInfo, updateUser } = useAuth();
  const [values, setValues] = useState({ ...userInfo });
  const [newTrigger, setNewTrigger] = useState(userInfo.conversion_triggers?.[0]?.trigger || "");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState(false);


  const handleValueChange = (key) => (value) => {
    setMutated(true);
    setValues(v => ({ ...v, [key]: value }));
  }


  const handleUpdate = async () => {
    setLoading(true);
    if (values.paypal_email) {
      await editAffiliate(userInfo.refersion_id, { paypal_email: values.paypal_email }).catch(console.error);
    } else {
      updateUser(values).finally(() => { setLoading(false); setMutated(false) });
    }
  }


  const handleChangeCode = () => {
    setLoading(true);
    console.log(userInfo)
    changeAffiliateCode(userInfo.refersion_id, userInfo.affiliate_code, userInfo.conversion_triggers[0]?.trigger, newTrigger).then(({ data }) => {
      updateUser({ conversion_triggers: data });
      console.log(data)
    }).catch(e => console.log(e.code, e.message) || setError("Unable to update your code")).finally(setLoading);
  }


  return (
    <Container maxWidth="sm" sx={{ pt: 2, height: '100%' }}>
      <Grid container direction='column' spacing={4} sx={{ height: '100%' }}>
        <Grid item>
          <h2>Your Account</h2>
        </Grid>
        <Grid item>
          <TextField label="IG Handle" value={values.handle || ""} onTextChange={handleValueChange('handle')} />
        </Grid>
        <Grid item>
          <TextField label="PayPal Email" value={values.paypal_email || ""} onTextChange={handleValueChange('payPalEmail')} />
        </Grid>
        <Grid item>
          Your Friends and Family Code:
          <Button sx={{ width: '100%', height: 60 }} variant='outlined' onClick={() => setDialogOpen(true)}>{userInfo.conversion_triggers?.[0]?.trigger || "Create your friends and family code"}</Button>
        </Grid>
        <Grid item xs />
        <Grid item>
          <ContinueButton title='update' disabled={!mutated} onClick={handleUpdate} />
        </Grid>
        <LoadingBackDrop open={!!loading} />
      </Grid>

      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>New Friends and Family Discount</DialogTitle>
        <DialogContent>
          <TextField error={!!error} value={newTrigger} onTextChange={v => setNewTrigger(v.toUpperCase().replace(/[^0-9a-z]/gi, ""))} />
          <Typography component='center' color='error'>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={!newTrigger.length > 3 || error} onClick={handleChangeCode}>submit</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}




