import React, { useEffect, useState } from 'react';
import { Avatar, Button, colors, FormControlLabel, Checkbox, Link, Grid, Box, Container, Typography } from '@material-ui/core';
import { LoadingBackDrop, TextField } from '../components/general';
import { LockOutlined } from '@material-ui/icons';
import { signIn } from '../firebase';
import { useNavigate } from 'react-router';
import firebase from 'firebase';
import Copyright from '../components/general/Copyright';




const styles = {
  paper: {
    marginTop: 8 * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins'
  },
  avatar: {
    margin: 4,
    backgroundColor: colors.blue[400],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    mt: 4,
    
  },
  submit: {
    margin: "3px 0 2px",
    fontFamily: 'Poppins',
    backgroundColor: colors.blue[400]
  },
};



export default function LoginView() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();



  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence[remember ? 'LOCAL' : 'NONE']).then(() => {

      signIn(email, password).then(() => nav('/dashboard')).catch(e => {
        console.error(e)
        setError('Wrong email/password');
        setLoading(false);
      })
    })
  }



  useEffect(() => {
    setError(false);
  }, [email, password])



  return (
    <Container component="main" maxWidth="xs">
      <div style={styles.paper}>
      <Grid item>
          <img src="https://cdn.shopify.com/s/files/1/1649/7215/files/PACK_LOGO_FINAL_JUSTTYPE_TM_3fe8f11d-7e48-405b-a6fe-1829b34e0b2c.png?v=1613784681" alt="logo" style={{ height: "4.4rem", paddingTop: 15, paddingBottom: 15 }} />
      </Grid>
        <Avatar style={styles.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5" fontFamily='Poppins'>
          Sign in
        </Typography>
        <Typography color='error' fontFamily='Poppins'>{error || " "}</Typography>
        <form style={styles.form} noValidate onSubmit={handleSubmit} >
          <TextField margin="normal" align='left' required id="email" label="Email Address" name="email" autoComplete="email" autoFocus value={email} onTextChange={setEmail} error={!!error} disabled={loading} />
          <TextField margin="normal" align='left' required name="password" label="Password" type="password" id="password" autoComplete="current-password" value={password} onTextChange={setPassword} error={!!error} disabled={loading} />
          <FormControlLabel
            control={<Checkbox checked={remember} onChange={({ target }) => setRemember(target.checked)} value="remember" color="primary" />}
            label={<Typography fontFamily='Poppins'>Remember me</Typography>}
          />
          <Button type="submit" fullWidth variant="contained"  color='primary' style={styles.submit} disabled={!(email && password) || error || loading}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot" variant="caption" fontFamily="Poppins">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="caption" fontFamily="Poppins">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>


      <LoadingBackDrop open={loading} />
    </Container>
  );
}