import { colors, Container, Grid, IconButton, LinearProgress, linearProgressClasses } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useEffect, useState } from "react";
import Step1View from "./Step1View";
import Step2View from "./newStep2View";
import Step3View from "./Step3View";
import { useAuth } from "../AuthProvider";
import Step4View from "./Step4View";
import { styled } from "@material-ui/styles";
import * as Icons from "react-icons/all";









export default function SignUpView() {
  const { userInfo, signUp, updateUser, signOut } = useAuth();
  const [step, setStep] = useState(userInfo.step || 0);



  useEffect(() => {
    setStep(userInfo.step || 0);
  }, [userInfo])



  useEffect(() => {
    console.log(userInfo)
    if (userInfo.step) {

      fetch(`https://a.klaviyo.com/api/identify?data=${btoa(JSON.stringify({
        token: "PPcZnb",
        properties: {
          email: userInfo.email,
          dognames: userInfo.pets.map(p => p.name).join(','),
          bdays: userInfo.pets.map(p => p.bday).join(','),
          bap_step: userInfo.step + 1
        }
      }))}`, {
        method: 'GET',
        headers: {
          Accept: 'text/html'
        }
      }).then(res => res.json()).then(j => {
        console.log('json', j)
      }).catch(console.error)

    }
  }, [userInfo])



  const BorderLinearProgress = styled(LinearProgress)(theme => ({
    borderRadius: 5,
    [`&.${linearProgressClasses.root}`]: {
      height: 8,
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.blue[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: colors.blue[400],
      borderRadius: 5,
      height: 8
    },
  }))
  //is mainly due to components having nested elements with their own styles //DONT DELETE



  return (
    <Container maxWidth="xl" sx={{ height: '100%' }}>
      <Grid container item direction='column' sx={{ height: '100%' }} >
        <Grid container item justifyContent="space-between" sx={{ paddingTop: 1 }}>
          <Grid item xs={1}>
            {step > 1 && (
              <IconButton onClick={() => setStep(s => s - 1)}>
                <ArrowBack />
              </IconButton>
            )}
          </Grid>


          <Grid item>
            <img src="https://cdn.shopify.com/s/files/1/1649/7215/files/PACK_LOGO_FINAL_JUSTTYPE_TM_3fe8f11d-7e48-405b-a6fe-1829b34e0b2c.png?v=1613784681" alt="logo" style={{ height: "4.4rem", paddingTop: 15, paddingBottom: 15 }} />
          </Grid>
          <Grid item xs={1} >
            {step > 0 && (
              <IconButton onClick={() => signOut()}>
                <Icons.CgClose />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <BorderLinearProgress variant="determinate" value={(step + 1) * 25} />
        </Grid>


        <Grid item xs justifyItems='center' style={{ width: "100%", height: "100%", overflowY: 'auto' }}>
          {step === 0 && <Step1View {...{ userInfo, signUp }} />}
          {step === 1 && <Step2View {...{ userInfo, updateUser }} />}
          {step === 2 && <Step3View {...{ userInfo, updateUser }} />}
          {step === 3 && <Step4View {...{ userInfo, updateUser }} />}
        </Grid>
      </Grid>
    </Container >
  )
}


