import { InputAdornment, TextField as MuiTextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";



const TextFieldStyle = withStyles({
  root: {
    color: '#333',
    marginTop: 10,
  }
})(MuiTextField)






export default function TextField({
  style = {},
  shrink = true,
  onTextChange = () => null,
  inputProps = {},
  InputProps = {},
  startAdornment,
  align = 'center',
  ...props
}) {
  return (
    <TextFieldStyle
      fullWidth
      variant='outlined'
      InputLabelProps={{ shrink }}
      onChange={({ target }) => onTextChange(target.value)}
      style={{
        color: '#333',
        textAlign: align,
        ...style
      }}
      inputProps={{
        ...inputProps,
        style: {
          textAlign: align,
          color: '#333',
        }
      }}
      InputProps={{
        ...InputProps,
        startAdornment: startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>,
      }}
      {...props}
    />
  )
}


