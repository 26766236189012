import firebase from 'firebase'

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
console.log(process.env.NODE_ENV);
process.env.NODE_ENV === 'development' && firebase.functions().useEmulator('10.1.10.194', 5001);






// AUTH
export const signIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);






// FUNCTIONS
export const getUserDoc = () => firebase.functions().httpsCallable('getUserDoc')();





export const setAttributes = (data) => firebase.functions().httpsCallable('setAttributes')(data);





export const verifyOrderNumber = (orderNumber, email) => firebase.functions().httpsCallable('verifyOrderNumber')({ orderNumber, email });





export const searchForOrderByEmail = (email) => firebase.functions().httpsCallable('searchForOrderByEmail')(email);





export const createAffiliate = (data) => firebase.functions().httpsCallable('createAffiliate')(data);





export const createAmbassador = (email) => firebase.functions().httpsCallable('createAmbassador')(email);





export const getCustomTokenByUid = (uid) => firebase.functions().httpsCallable('getCustomTokenByUid')(uid);





export const setPassword = (pass) => firebase.auth().currentUser.updatePassword(pass).catch(e => {
  console.log(e);
  if (e.code === "auth/requires-recent-login") {
    const user = firebase.auth().currentUser;
    getCustomTokenByUid(user.uid).then(token => {
      firebase.auth().signInWithCustomToken(token.data).then(u => {
        setPassword(pass)
      })
    })

  }
});





export const uploadImages = async ({ id, handle = null }, files = []) => {
  var storageRef = firebase.storage().ref();


  return Promise.all(files.map(async f => {
    const doc = {
      resolution: f.resolution,
      submitter: { handle },
      timestamp: (new Date()).toJSON(),
    }
    const imageRef = storageRef.child(`/${handle}/${f.file.name}`); // TODO -- WHAT IF IT ALREADY EXISTS?
    await imageRef.put(f.file).then(async (snap) => {
      doc.fullPath = snap.ref.fullPath;
      doc.url = await snap.ref.getDownloadURL(); // doesnt work if upload isnt finished -- it just hangs

    });
    console.log(id, doc)
    const colRef = firebase.firestore().collection('ambassadors').doc(id).collection('submissions'); // TODO -- WHY IS THIS HANGING
    await colRef.get();
    const res = await colRef.add(doc).catch(console.error)
    console.log(res)
    return doc
  }))
}





export const getSubmissions = async (id) => {
  if (id) {
    const col = firebase.firestore().collection('ambassadors').doc(id).collection('submissions')
    await col.get();
    return col.orderBy('timestamp', 'desc').get().then(d => d.docs.map(dd => ({ ...dd.data(), id: dd.id })))
  } else {
    return firebase.firestore().collectionGroup('submissions').orderBy('timestamp', 'desc').limit(50).get().then(d => d.docs.map(dd => ({ ...dd.data(), id: dd.id })))
  }
}





export const getAmbassadors = () => {
  return firebase.firestore().collection('ambassadors').get().then(d => d.docs.map(dd => ({ ...dd.data(), id: dd.id })))
}





export const migrate = (page) => firebase.functions().httpsCallable('migrate')(page);






export const deleteAmbassadors = (ambassadors = []) => firebase.functions().httpsCallable('deleteAmbassadors')(ambassadors);






export const getAffiliate = (id) => firebase.functions().httpsCallable('getAffiliate')(id);






export const editAffiliate = (id, data) => firebase.functions().httpsCallable('editAffiliate')({ id, data });






export const changeAffiliateCode = (affiliate_id, affiliate_code, oldTrigger, newTrigger) => firebase.functions().httpsCallable('changeAffiliateCode')({ affiliate_id, affiliate_code, oldTrigger, newTrigger });






export const getConversions = (affiliate_id) => firebase.functions().httpsCallable('getConversions')(affiliate_id);
