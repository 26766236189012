import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, AvatarGroup, Box, Divider, Drawer, List, Typography } from '@material-ui/core';
import * as Icons from 'react-icons/all';
import NavItem from './NavItem';
import avatars from '../avatars';




const items = [
  {
    href: '/dashboard',
    icon: Icons.RiDashboardFill,
    title: 'Dashboard'
  },
  {
    href: '/dashboard/photo-submission',
    icon: Icons.FaCamera,
    title: 'Photo Submission'
  },
  {
    href: '/dashboard/models',
    icon: Icons.FaDog,
    title: 'Models'
  },
  {
    href: '/dashboard/payouts',
    icon: Icons.FiDollarSign,
    title: 'Payouts'
  },
  {
    href: '/dashboard/account',
    icon: Icons.MdAccountCircle,
    title: 'Account'
  },
];



const DashboardSidebar = ({ onMobileClose, openMobile, userInfo }) => {
  const location = useLocation();

  const models = userInfo.pets || [];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2 }} >
        <AvatarGroup max={3}>
          {models.map((m,i) => (
            <Avatar key={i} component={RouterLink} src={avatars[m.avatarId].url} sx={{ cursor: 'pointer', width: 64, height: 64 }} to="/dashboard/models" />
          ))}
        </AvatarGroup>
        <Typography color="textPrimary" variant="h5" >
          {models.map(m => m.name).join(", ")}
        </Typography>
        <Typography color="textSecondary" variant="body2" >
          {userInfo.handle ? "@"+userInfo.handle : <RouterLink to='/dashboard/account'>Add IG handle</RouterLink>}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ p: 2 }}>
        <NavItem href={"/logout"} title={"Logout"} icon={Icons.GrLogout} />
      </Box>
    </Box>
  );

  return (
    <>
      {/* MOBILE */}
      <Drawer
        sx={{ display: { xs: 'block', lg: 'none' } }}
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        {content}
      </Drawer>


      {/* DESKTOP */}
      <Drawer
        sx={{ display: { xs: 'none', lg: 'block' } }}
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            top: 64,
            height: 'calc(100% - 64px)'
          }
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
