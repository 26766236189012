import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@material-ui/core"
import { forwardRef } from "react"





export default function ErrorPopUp({ open, onClose = () => null, content = "", children, ...props }) {

  return (
    <Dialog open={open} keepMounted onClose={onClose}
      TransitionComponent={Transition} {...props}>
      <DialogTitle>Uh Oh!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
          <br />
          <br />
      If the issue persists, contact us at bark@packleashes.com
    </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>close</Button>
      </DialogActions>
    </Dialog>
  )
}


const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />)
