import { Grid, Link, Typography } from "@material-ui/core";
import { useState } from "react";
import { ContinueButton, ErrorPopUp, TextField, LoadingBackDrop } from "../components/general";
import emailValidation from "../helpers/emailValidation";




export default function Step1View({ signUp }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);



  const handleSubmit = () => {
    setLoading(true);
    signUp(email).catch(e => {
      if (e.code === 'unauthenticated') {
        setError("Looks like there's already an account with this email. Please login.");
      } else setError(e.message || e.code);
    }).finally(setLoading);

  }


  return (
    <Grid container direction='column' justify='center' wrap='nowrap' alignItems='center' sx={styles.root} >
      <Grid item xs >
        <center>
          <Typography sx={styles.header} >Become an Ambassador & Sign Up Now!!</Typography>
          <Typography variant='caption' >(Just enter your email below)</Typography>
        </center>
      </Grid>


      <Grid item>
        <TextField autoFocus type="email" label="email" value={email} onTextChange={setEmail} placeholder='email' />
        <center>
          <Link href="/login" color="primary" variant='body2' sx={styles.link}>Already have an account? Log in</Link>
        </center>
      </Grid>

      <Grid item xs />

      <Grid item sx={{ width: '100%' }}>
        <ContinueButton disabled={!emailValidation(email)} onClick={handleSubmit} >Continue</ContinueButton>
      </Grid>





      <LoadingBackDrop open={!!loading} />
      <ErrorPopUp open={!!error} onClose={() => setError(false)} content={error} />
    </Grid >

  )
}





const styles = {
  root: {
    height: '100%',
    width: '100%',
  },
  header: {
    color: '#333',
    fontSize: '2.4rem',
    fontWeight: 800,
  },
  paper: {
    position: 'absolute',
    width: 400,
  }
}