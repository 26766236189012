import { Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { getSubmissions } from "../firebase";



const columns = [
  {
    field: 'url',
    headerName: 'Image',
    flex: 0.5,
    renderCell: (a) => <img src={a.value} alt={a.id} style={{ width: '100%' }} />,
    filterable: false,
    sortable: false,
  },
  {
    field: 'submitter',
    headerName: 'Handle',
    renderCell: (a) => a.value.handle,
    type: 'string',
    flex: 1,
  },
  {
    field: 'resolution',
    headerName: 'Resolution',
    flex: 1,
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    type: 'date',
    valueFormatter: (a) => (new Date(a.value)).toLocaleString(),
    flex: 1,
  }]



export default function SubmissionsView() {
  const [isLoading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState([]);


  useEffect(() => {
    setLoading(true)
    getSubmissions().then(setSubmissions).catch(console.error).finally(setLoading);
  }, [])


  return (
    <Grid container direction='column' style={{ height: '100%' }} wrap='nowrap' >
      <Grid item xs style={{ overflowY: 'auto' }}>
        <DataGrid
          loading={!!isLoading}
          columns={columns}
          rows={submissions}
          autoHeight
          rowHeight={150}
          checkBoxSelection='true'
          pageSize={10}
          rowsPerPageOptions={[]}
        />
      </Grid>
    </Grid>
  )
}