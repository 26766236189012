


const avatars = [
  {id: 0, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_xx_generic.png"},
  {id: 1, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_01.png"},
  {id: 2, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_02.png"},
  {id: 3, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_03.png"},
  {id: 4, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_04.png"},
  {id: 5, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_05.png"},
  {id: 6, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_06.png"},
  {id: 7, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_07.png"},
  {id: 8, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_08.png"},
  {id: 9, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_10_border_collie.png"},
  {id: 10, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_11_husky.png"},
  {id: 11, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_12_gsd.png"},
  {id: 12, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_13_yorkie.png"},
  {id: 13, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_14_schnauzer.png"},
  {id: 14, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_15_boxer.png"},
  {id: 15, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_16_shih_tzu.png"},
  {id: 16, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_17_aussie.png"},
  {id: 17, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_18_goldendoodle.png"},
  {id: 18, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_19_beagle.png"},
  {id: 19, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_20_chihuahua.png"},
  {id: 20, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_21_maltese.png"},
  {id: 21, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_22_pommeranian.png"},
  {id: 22, url:"https://dotcms-ui.chewy.com/images/pet-profile/avatars/avatar_dog_23_pug.png"},
]


export default avatars;