import { Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { ContinueButton, ErrorPopUp, TextField, LoadingBackDrop } from "../components/general";
import { createAffiliate, setPassword } from '../firebase';
import { useNavigate } from 'react-router-dom';



export default function Step4View({ updateUser, userInfo }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const nav = useNavigate();


  const handleSubmit = async () => {
    setLoading(true);
    await setPassword(pass1);
    await createAffiliate(userInfo);
    await updateUser({ step: 4 })
    nav('/dashboard');
  }



  return (
    <Grid container direction='column' alignItems='center' wrap="nowrap" sx={styles.root} >
      <Grid item sx={styles.headerContainer}>
        <center>
          <Typography sx={styles.header} >Last step!</Typography><br />
        </center>
      </Grid>

      <Grid item xs />


      <Grid item sx={{ width: '60%', pb: 2 }}>
        <TextField type='password' value={pass1} onTextChange={setPass1} fullWidth label="password" />
      </Grid>


      <Grid item sx={{ width: '60%' }}>
        <TextField type='password' value={pass2} onTextChange={setPass2} label="confirm password" />
      </Grid>

      <Grid item >
        <Typography color={pass1.length < 6 && 'error'} variant='caption'>must contain at least 6 characters</Typography><br />
      </Grid>




      <Grid item xs />



      <Grid item sx={{ width: '100%' }}>
        <ContinueButton disabled={!(pass1.length > 5 && pass1 === pass2)} onClick={handleSubmit} title='Complete' />
      </Grid>



      <LoadingBackDrop open={loading} />
      <ErrorPopUp open={!!error} onClose={setError} content={error} />
    </Grid >
  )
}



const styles = {
  root: {
    height: '100%',
    width: '100%',
    //maxWidth: 600,
    alignItems: 'center',
    overflowY: 'hidden'
  },
  headerContainer: {
    mt: 2
  },
  header: {
    color: '#333',
    fontSize: '2.4rem',
    fontWeight: 800,
  },
  content: {
    width: '100%'
  },
}