import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { forwardRef } from "react";






export default forwardRef(({
  open,
  content = "",
  ...props
}, ref) => (
  <Backdrop ref={ref} open={open} style={{ zIndex: 9999 }} {...props}>
    <Grid container justifyContent='center' alignItems='center' direction='column'>
      <Grid item>
        <CircularProgress /><br />
      </Grid>
      <Grid item>
        {content}
      </Grid>
    </Grid>
  </Backdrop>
))