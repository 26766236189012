import { Grid, Typography, Dialog, DialogContent, Button, Link, DialogActions } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { ContinueButton, ErrorPopUp, LoadingBackDrop, TextField } from "../components/general";
import { verifyOrderNumber } from "../firebase";




export default function Step3View({ userInfo, updateUser }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState(userInfo.email);



  useEffect(() => {
    if (!userInfo.orderNumber) {
      verifyOrderNumber("", userInfo.email).then(({ data }) => {
        if (data) {
          updateUser({ orderNumber: data })
        }
      }).finally(setLoading)
    } else setLoading(false)
  }, [updateUser, userInfo.email, userInfo.orderNumber])



  const handleContinue = () => {
    setLoading(true);

    updateUser({ step: 3 }).catch(e => {
      setError(e.message || e.code);
    }).finally(setLoading);

  }


  const handleVerifyOrder = () => {
    setLoading(true)
    verifyOrderNumber(orderNumber, email).then(async ({ data }) => {
      if (data) {
        await updateUser({ orderNumber });
        setDialogOpen(false);
      } else {
        setVerifyError("The order email and order number does not match our records");
      }
    }).catch(console.error).finally(setLoading);
    //setDialogOpen(false)
  }


  return (
    <Grid container direction='column' justify='center' wrap='nowrap' alignItems='center' sx={styles.root} >
      {/*}
      <div style={{ position: 'absolute', bottom: 40, width: '70%', right: 0, overflow: 'hidden', padding: 0, maxWidth: 400 }}>
        <img src='/dog.png' style={{ height: '100%', width: '100%' }} />
      </div>
      */}

      <Grid item sx={styles.headerContainer}>
        <center>
          <Typography sx={styles.header} >{userInfo.orderNumber ? <> <h6> Order {userInfo.orderNumber}</h6><CheckCircleOutline fontSize='30px' style={{ color: 'green' }} /></> : "You're So Close!"}</Typography>
        </center>
      </Grid>





      {/*<Grid item sx={{ py: 1, width: '100%' }} >
        {!userInfo.orderNumber && <ContinueButton onClick={() => setDialogOpen(true)} title={"Or Enter Order #"} />}
      </Grid>*/}
      <Grid container item wrap='nowrap' spacing={5} alignItems='center' sx={{ mb: 2, px: 2 }}>
        <Grid item xs={3}>
          <img src='/Icon1.png' alt='icon1' style={styles.image} />
        </Grid>
        <Grid item xs>
          <Typography sx={styles.content}>Dont forget your discount!</Typography>
        </Grid>
      </Grid>

      <Grid container item wrap='nowrap' spacing={5} alignItems='center' sx={{ mb: 2, px: 2 }}>
        <Grid item xs={3}>
          <img src='/Icon2.png' alt='icon2' style={styles.image} />
        </Grid>
        <Grid item xs>
          <Typography sx={styles.content}>Your Purchase Donates 4lbs of food!</Typography>
        </Grid>
      </Grid>

      <Grid container item wrap='nowrap' spacing={5} alignItems='center' sx={{ px: 2 }}>
        <Grid item xs={3}>
          <img src='/Icon3.png' alt='icon1' style={styles.image} />
        </Grid>
        <Grid item xs>
          <Typography sx={styles.content}>Pics Shared on @packleashes</Typography>
        </Grid>
      </Grid>

      <Grid item xs />

      <Grid item sx={{ width: '100%' }}>
        <center>
          <Link hidden={userInfo.orderNumber} onClick={() => setDialogOpen(true)} color="primary" variant='body2' sx={styles.link}>Already have an order? Click here</Link>
        </center>
        <ContinueButton onClick={() => userInfo.orderNumber ? handleContinue() : window.location.href = "https://packleashes.com/collections/all"} title={userInfo.orderNumber ? 'Finish sign up' : 'Gear Up Now!'} />
      </Grid>






      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(false)} >
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <h3>Verify your order email and order number</h3>
            </Grid>
            <Grid item>
              <TextField label='Order Email' placeholder='Order Email' value={email} onTextChange={v => setEmail(v) || setVerifyError()} />
            </Grid>
            <Grid item>
              <TextField label='Order Number' placeholder='Order #' value={orderNumber} onTextChange={v => setOrderNumber(v.replace(/[^0-9]/g, "").slice(0, 6)) || setVerifyError()} />
            </Grid>
            <Grid item style={{ color: 'red' }}>
              <center>{verifyError}</center>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' disabled={orderNumber.length < 5 || !email.length || loading} onClick={handleVerifyOrder} >Submit</Button>
        </DialogActions>
      </Dialog>


      <LoadingBackDrop open={!!loading} />
      <ErrorPopUp open={!!error} onClose={() => setError(false)} content={error} />
    </Grid >
  )
}



const styles = {
  root: {
    height: '100%',
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    fontSize: '2.4rem',
    paddingTop: 2,
    overflow: 'hidden'
  },
  headerContainer: {
    paddingBottom: 2
  },
  link: {
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    //height: '30%',
    //padding: 1,
    //marginTop: 10

  },
  header: {
    color: '#333',
    fontSize: '2.4rem',
    fontWeight: 800,
  },
  content: {
    width: '100%',
    fontSize: '1rem',
    paddingBottom: 2,
  },

}