import DashboardView from "./views/DashboardView";
import LoginView from "./views/LoginView";
import SignUpView from "./views/SignUpView";
import { Navigate } from 'react-router-dom';
import DashboardLayout from "./components/DashboardLayout";
import MainLayout from "./components/MainLayout";
import PhotoSubmissionView from "./views/PhotoSubmissionView";
import SubmissionsView from "./views/SubmissionsView";
import AmbassadorsView from "./views/AmbassadorsView";
import AdminView from "./views/AdminView";
import { useMemo } from "react";
import ModelsView from "./views/ModelsView";
import AccountView from "./views/AccountView";
import LogoutView from "./views/LogoutView";
import PayoutView from "./views/PayoutView";
import ForgotView from "./views/ForgotView";




/**
 * @param {Boolean} isAdmin
 * @returns {import("react-router").PartialRouteObject[]}
 */
export default function CreateRoutes({ isSignedIn, isAdmin = true, step }) { //TODO -- remove default value for isAdmin
  return useMemo(() => [
    {
      path: '/dashboard',
      element: isSignedIn ? (step > 3 ? <DashboardLayout /> : <Navigate to='/signup' />) : <Navigate to="/login" />,
      children: [
        { path: '/', element: <DashboardView /> },
        { path: 'photo-submission', element: <PhotoSubmissionView /> },
        { path: 'models', element: <ModelsView /> }, 
        { path: 'account', element: <AccountView /> },
        { path: 'payouts', element: <PayoutView /> },
      ]
    },
    {
      path: '/admin',
      element: isSignedIn ? (isAdmin ? <MainLayout /> : <Navigate to='/dashboard' />) : <Navigate to="/login" />,
      children: [
        {
          path: '/',
          element: <AdminView />
        },
        {
          path: 'ambassadors',
          element: <AmbassadorsView/>
        },
        {
          path: 'submissions',
          element: <SubmissionsView />
        },
        {
          path: '/*',
          element: <Navigate to='/admin' />
        }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: isSignedIn && step > 3 ? <Navigate to='/dashboard' /> : <Navigate to='/signup' />,
        },
        {
          path: 'signup',
          element: isSignedIn && step > 3 ? <Navigate to='/dashboard' /> : <SignUpView />,
        },
        {
          path: 'login',
          element: isSignedIn && step > 3 ? <Navigate to='/dashboard' /> : <LoginView />,
        },
        {
          path: 'forgot',
          element: isSignedIn ? <Navigate to='/dashboard' /> : <ForgotView />,
        },
        {
          path: 'logout',
          element: <LogoutView />,
        },
      ]
    },
    {
      path: '/*',
      element: <Navigate to='/' />,
    }
  ], [isAdmin, isSignedIn, step])
}