import { Button, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { getAmbassadors, deleteAmbassadors } from "../firebase";



const columns = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'step',
    headerName: 'Step',
    type: 'number',
    flex: 1,
  },
  {
    field: 'pets',
    headerName: 'Model Count',
    renderCell: (a) => a.value?.length || 0,
    type: 'number',
    flex: 1,
  },
  {
    field: 'photos',
    headerName: 'Photo Count',
    renderCell: (a) => a.value?.length ?? 0,
    type: 'number',
    flex: 1,
  },
  {
    field: 'orderNumber',
    headerName: 'Order #',
    type: 'string',
    flex: 1,
  }]



export default function AmbassadorsView() {
  const [isLoading, setLoading] = useState(false);
  const [ambassadors, setAmbassadors] = useState([]);
  const [selected, setSelected] = useState([]);


  const handleDelete = () => {
    console.log(selected)
    setLoading(true)
    deleteAmbassadors(selected).then(() => {
      setAmbassadors(a => a.filter(aa => selected.includes(aa.id)));
      setSelected([])
    }).catch(console.error).finally(setLoading);
  }


  useEffect(() => {
    setLoading(true)
    getAmbassadors().then(setAmbassadors).catch(console.error).finally(setLoading);
  }, [])


  return (
    <Grid container direction='column' style={{ height: '100%', width: '100%' }} wrap='nowrap' >
      <Grid item>
        <Button onClick={handleDelete}>Delete</Button>
      </Grid>
      <Grid item xs style={{ overflowY: 'auto' }}>
        <DataGrid
          loading={!!isLoading}
          columns={columns}
          rows={ambassadors}
          autoHeight
          pageSize={10}
          rowsPerPageOptions={[]}
          checkboxSelection
          disableSelectionOnClick
          onRowSelected={(a) => a.isSelected ? setSelected(s => [...s, a.data.id]) : setSelected(s => s.filter(ss => ss !== a.data.id))}
          
        />
      </Grid>
    </Grid>
  )
}