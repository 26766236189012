import { Avatar, Box, Card, CardContent, Grid, Typography, colors } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as Icons from 'react-feather';
import { getConversions } from '../../firebase';







export default function CurrentPayout({ userInfo }) {
  const [isLoading, setLoading] = useState(true);
  const [payout, setPayout] = useState(0);

  const from = new Date();
  from.setMonth(from.getMonth() - 0)
  from.setHours(0, 0, 0, 0);
  from.setDate(1);
  const to = new Date(from);
  to.setMonth(to.getMonth() + 1);
  to.setDate(0);

  const [range,] = useState({ from, to });

  useEffect(() => {
    getConversions({ affiliate_id: userInfo.refersion_id, from: range.from.valueOf() / 1000, to: range.to.valueOf() / 1000 }).then(({ data }) => {
      setPayout(data.map(d => Number(d.commission_total)).reduce((a, b) => a + b, 0));
    }).catch(console.error).finally(setLoading);
  }, [userInfo.refersion_id, range])


  const p = userInfo.paypal_email;
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }} >
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant='h6' >
              NEXT PAYOUT { }
            </Typography>
            <Typography color="textPrimary" variant={p ? "h3" : 'body'} >
              {p ? "$" + payout.toFixed(2) : "Set up your PayPal info"}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar sx={{ backgroundColor: p ? colors.green[600] : colors.red[400], height: 56, width: 56 }} >
              {p ? <Icons.DollarSign /> : <Icons.AlertCircle />}
            </Avatar>
          </Grid>
        </Grid>
        <Box sx={{ pt: 2, display: 'flex', alignItems: 'center' }} >
          <Typography color="textSecondary" variant="caption" >
            {p && range.from.toLocaleDateString() + " - " + range.to.toLocaleDateString()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}