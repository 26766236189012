const breeds = [
  "Affenpinscher",
  "Afghan",
  "Airedale Terrier",
  "Akita",
  "Miniature American Eskimo",
  "Standard American Eskimo",
  "American Staffordshire Terrier",
  "Anatolian Shepherd",
  "Australian Cattle Dog",
  "Australian Shepherd",
  "Australian Terrier",
  "Basenji",
  "Basset Hound",
  "Beagle",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Malinois",
  "Belgian Sheepdog",
  "Belgian Tervuren",
  "Bergamasco",
  "Bernese Mountain Dog",
  "Bichon Frise",
  "Black Russian Terrier",
  "Bloodhound",
  "Boerboel",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Briard",
  "Brittany",
  "Brussels Griffon",
  "Bull Terrier",
  "Miniature Bull Terrier",
  "American Bulldog",
  "English Bulldog",
  "French Bulldog",
  "Bullmastiff",
  "Cairn Terrier",
  "Canaan Dog",
  "Cane Corso",
  "Catahoula",
  "Cavalier King Charles Spaniel",
  "Cesky Terrier",
  "Chesapeake Bay Retriever",
  "Long Haired Chihuahua",
  "Smooth Haired Chihuahua",
  "Chinese Crested",
  "Chinese Crested- Powderpuff",
  "Chinese Shar-Pei",
  "Chinook",
  "Chow Chow",
  "Clumber Spaniel",
  "Cockapoo",
  "American Cocker Spaniel",
  "English Cocker Spaniel",
  "Collie- Rough Coat",
  "Collie- Smooth Coat",
  "Coonhound",
  "Corgi",
  "Coton de Tulear",
  "Curly-Coated Retriever",
  "Long Haired Dachshund",
  "Smooth Dachshund",
  "Wirehaired Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Doberman Pinscher",
  "Dogo Argentino",
  "English Toy Spaniel",
  "Entlebucher Mountain Dog",
  "Field Spaniel",
  "Finnish Lapphund",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "Smooth Fox Terrier",
  "Toy Fox Terrier",
  "Wirehaired Fox Terrier",
  "American Foxhound",
  "English Foxhound",
  "German Shepherd",
  "German Shorthaired Pointer",
  "German Wirehaired Pointer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Goldendoodle",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greyhound",
  "Harrier",
  "Havanese",
  "Ibizan Hound",
  "Icelandic  Sheepdog",
  "Irish Terrier",
  "Irish Water Spaniel",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Rough Jack Russel Terrier",
  "Smooth Jack Russel Terrier",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "Komondor",
  "Kuvasz",
  "Labradoodle",
  "Labrador Retriever",
  "Lakeland Terrier",
  "Leonberger",
  "Lhasa Apso",
  "Lowchen",
  "Maltese",
  "Maltipoo",
  "Standard Manchester Terrier",
  "Toy Manchester Terrier",
  "Mastiff",
  "Neapolitan Mastiff",
  "Tibetan Mastiff",
  "Newfoundland",
  "Norfolk Terrier",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwegian Lundehund",
  "Norwich Terrier",
  "Nova Scotia Duck Tolling Retriever",
  "Old English Sheepdog",
  "Otterhound",
  "Papillon",
  "Parson Russell Terrier",
  "Rough Parson Russell Terrier",
  "Pekingese",
  "Peruvian Inca Orchid",
  "Petit Basset Griffon Vendeen",
  "Pharaoh Hound",
  "American Pit Bull Terrier",
  "Plott Hound",
  "Pointer",
  "Polish Lowland Sheepdog",
  "Pomeranian",
  "Miniature Poodle",
  "Poodle",
  "Toy Poodle",
  "Portuguese Podengo Pequeno",
  "Rough Portuguese Podengo Pequeno",
  "Portuguese Water Dog",
  "Pug",
  "Puggle",
  "Puli",
  "Pumi",
  "Rat Terrier",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Saint Bernard",
  "Saluki",
  "Samoyed",
  "Schipperke",
  "Giant Schnauzer",
  "Miniature Schnauzer",
  "Standard Schnauzer",
  "Schnoodle",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "English Setter",
  "Irish Setter",
  "Irish Red and White Setter",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Siberian Husky",
  "Silky Terrier",
  "Skye Terrier",
  "Sloughi",
  "Spinone Italiano",
  "English Springer Spaniel",
  "Welsh Springer Spaniel",
  "Staffordshire Bull Terrier",
  "Sussex Spaniel",
  "Swedish Vallhund",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Vizsla",
  "Wirehaired Vizsla",
  "Weimaraner",
  "Welsh Terrier",
  "West Highland White Terrier",
  "Soft Coated Wheaten Terrier",
  "Whippet",
  "Wirehaired Pointing Griffon",
  "Xoloitzcuintli (Mexican Hairless Dog)",
  "Yorkshire Terrier",
  "African Boerboel",
  "South Russian Ovcharka",
  "Japanese Spitz",
  "Klein German Spitz",
  "Patterdale Terier",
  "Mittel German Spitz",
  "Dogue de Bordeaux",
  "Fila Brasilerio",
  "Brazilian Terrier",
  "German Hunting Terrier",
  "Pembroke Welsh Corgi",
  "Cardigan Welsh Corgi",
  "English Water Spaniel",
  "Japanese Spaniel",
  "Prazsky Krysarik",
  "German Pinscher",
  "Catahoula Leopard Dog",
  "Water Spaniel- Irish",
  "Redbone Coonhound",
  "Gordon Setter",
  "Bolognese",
  "Japanese Terrier",
  "Coonhound-Black and Tan",
  "Wetterhoun",
  "Alapaha Blue Blood Bulldog",
  "Phalene",
  "Lancashire Heeler",
  "Miniature Pinscher",
  "Maremma Sheepdog",
  "Toy Mexican Hairless",
  "Estrela Mountain Dog",
  "Central Asian Ovtcharka",
  "Volpino",
  "Kyi-Leo",
  "Akbash Dog",
  "Kangal Dog",
  "Mixed / Unknown",
  "Afghan Hound",
  "Alaskan Klee Kai",
  "Alaskan Malamute",
  "American English Coonhound",
  "American Hairless Terrier",
  "American Leopard Hound",
  "American Water Spaniel",
  "Appenzeller Sennenhound",
  "Argentine Dogo",
  "Australian Kelpie",
  "Australian Koolie",
  "Azawakh",
  "Barbet",
  "Basset Fauvre de Bretagne",
  "Belgian Laekenois",
  "Berger Picard",
  "Biewer Terrier",
  "Black and Tan Coonhound",
  "Black and Tan English Toy Terrier",
  "Bluetick Coonhound",
  "Bracco Italiano",
  "Braque du Bourbonnais",
  "Braques Francais Pyrenean",
  "Broholmer",
  "Bulldog",
  "Caucasian Ovcharka",
  "Central Asian Shepherd Dog",
  "Cirneco dell'Etna",
  "Cocker Spaniel",
  "Czaechoslovakian Vlcak",
  "Danish-Swedish Farmdog",
  "Deutcher Wachtelhund",
  "Drentsche Patrijshond",
  "Drever",
  "Dutch Shepherd",
  "Eurasier",
  "French Spaniel",
  "German Longhaired Pointer",
  "German Spitz",
  "Grand Basset Griffon Vendeen",
  "Hamiltonstovare",
  "Hokkaido",
  "Hovawart",
  "Jagdterrier",
  "Jindo",
  "Kai Ken",
  "Karelian Bear Dog",
  "Kishu Ken",
  "Kromfohrlander",
  "Lagotto Romagnolo",
  "Large Munsterlander",
  "Mudi",
  "Munsterlander Pointer",
  "Nederlandse Kooikerhondje",
  "Norrbottenspets",
  "Perro de Presa Canario",
  "Podenco",
  "Portuguese Pointer",
  "Portuguese Sheepdog",
  "Prenean Shepherd",
  "Presa Canario",
  "Pudelpointer",
  "Pyrenean Mastiff",
  "Rafeiro do Alentejo",
  "Russian Toy",
  "Russian Tsvetnaya Bolonka",
  "Schapendoes",
  "Shikoku",
  "Slovensky Cuvac",
  "Slovensky Kopov",
  "Staghound",
  "Swedish Lapphund",
  "Thai Ridgeback",
  "Tornjak",
  "Tosa",
  "Transylvanian Hound",
  "Treeing Tennesee Brindle",
  "Treeing Walker Coonhound",
  "Working Kelpie",
  "American Bully",
  "Spanish Water Dog",
  "Blue Heeler",
  "Mountain Cur",
  "McNab Sheperd",
  "American Eskimo",
  "Austrailian Stumpy Tail Cattle Dog",
  "Bavarian Mountain Scent Hound",
  "Blackmouth Cur",
  "Carolina Dog",
  "Chihuahua",
  "Croatian Sheepdog",
  "Dachshund",
  "Hanoverian Scenthound",
  "Lapponian Herder",
  "Manchester Terrier (Standard)",
  "Manchester Terrier (Toy)",
  "Porcelaine",
  "Pyrenean Shepard",
  "Romanian Mioritic Shepard",
  "Segugio Italiano",
  "Slovakian Wirehaired Pointer",
  "Small Munsterlander Pointer",
  "Spanish Mastiff",
  "Stabyhoun",
  "Taiwan Dog",
  "Teddy Roosevelt Terrier",
  "Yakutian Laika",
  "English Shepherd",
  "Texas Blue Lacy",
  "Westie-poo",
  "Cur",
  "Griffon Belge",
  "Finnish Hound",
  "Husky",
  "East Siberian Laika",
  "Russo-European Laika",
  "West Siberian Laika",
  "Lapphund",
  "Shiloh Shepherd",
  "Shih-poo",
  "Blenheim Spaniel",
  "Indian Spitz",
  "Nordic/Norrbottenspets Spitz",
  "Patterdale Terrier",
  "Thai Bangkaew",
  "Peekapoo",
  "Peekapom"
]



export default breeds