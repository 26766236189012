import { useEffect, useState, createContext, useContext, useReducer } from 'react';
import { createAmbassador, getUserDoc, setAttributes, getCustomTokenByUid } from './firebase';
import firebase from 'firebase';
import { useLocation, useNavigate } from 'react-router';
import { getAffiliate } from './firebase';








const authContext = createContext();




export function AuthProvider({ children }) {
  const value = useProvideAuth();
  return <authContext.Provider {...{ value, children }} />
}




export const useAuth = () => {
  return useContext(authContext);
}




function useProvideAuth() {
  const [isSignedIn, setSignedIn] = useState(null);
  const [userInfo, userDispatch] = useReducer((user, action) => {
    if (action.update) {
      return { ...user, ...action.update };
    } else if (action.set) {
      return { ...user, ...action.set };
    } else if (action.reset) {
      return {}
    } else console.error(action);
  }, {})
  const { search } = useLocation();
  const nav = useNavigate();



  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const uid = searchParams.get('uid');

    if (uid) {
      getCustomTokenByUid(uid).then(({ data }) => {
        firebase.auth().signInWithCustomToken(data);
        nav('/signup');
      }).catch(e => {
        console.error(e);
        nav('/login');
      })
    } else {
      firebase.auth().onIdTokenChanged(async user => {
        console.log({ user });
        if (user) {
          getUserDoc().then(({ data }) => {
            document.cookie = `bap_uid=${user.uid}; domain=.packleashes.com; path=/; expires=Thu, 01 Jan 2030 00:00:00 UTC`;
            console.log(data)
            userDispatch({ set: { step: 1, email: user.email, ...data } });
            setSignedIn(!!user);
          }).catch(console.error);
        } else {
          setSignedIn(false);
          userDispatch({ reset: true });
        }

      })
    }
  }, [search, nav])





  useEffect(() => {
    if (userInfo.step === 4 && userInfo.refersion_id) {
      getAffiliate(userInfo.refersion_id).then(({ data }) => console.log(data) || updateUser(data)).catch(console.error)// TODO
    }
  }, [userInfo.refersion_id, userInfo.step])





  const signUp = (email) => {
    return createAmbassador(email).then(({ data }) => firebase.auth().signInWithCustomToken(data));
  }




  const signIn = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }




  const signOut = () => {
    document.cookie = `bap_uid=; domain=.packleashes.com; path=/; expires=Thu, 01 Jan 2000 00:00:00 UTC`;
    return firebase.auth().signOut();
  }




  const updateUser = (data) => {
    return setAttributes(data).then(({ data }) => {
      userDispatch({ set: data });
    });
  }







  return {
    isReady: isSignedIn !== null,
    signUp,
    signIn,
    signOut,
    isSignedIn: !!isSignedIn,
    userInfo,
    updateUser
  }


};
