import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { migrate } from "../firebase";



export default function AdminView() {
  const nav = useNavigate();


  return (
    <Grid container direction='column' style={{ height: '100%' }} justifyContent="space-evenly" alignItems='center'  >
      <Grid item >
        <Button variant="outlined" onClick={() => nav('/admin/submissions')}>Submissions</Button>
      </Grid>
      <Grid item >
        <Button variant="outlined" onClick={() => nav('/admin/ambassadors')}>Ambassadors</Button>
      </Grid>
    </Grid>
  )
}